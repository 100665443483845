.userTextMessage {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  align-items: flex-end;
  margin-bottom: 12px;
  position: relative;
  white-space: pre-wrap;
}

.userTextShowDate {
  padding-top: 35px;
}

.userTextShowTime {
  padding-bottom: 10px;
}

.chatUserTextBubble {
  min-height: min-content;
  max-width: 263px;
  background: #c0a76e;
  border-radius: 26px;
  padding: 11px 16px;
  word-wrap: break-word;
  line-height: normal;
  font-size: 13px;
  letter-spacing: 1px;
}

.chatUserCreateTime {
  line-height: normal;
  font-size: 10px;
  text-align: right;
  letter-spacing: 1px;
  color: #a0a0a0;
  position: absolute;
  right: 16px;
  bottom: -7px;
}

.chatUserCreateDate {
  line-height: normal;
  position: absolute;
  font-size: 12px;
  color: #a0a0a0;
  left: 0;
  right: 0;
  margin: auto;
  width: 100%;
  text-align: center;
  top: 10px;
}
.activeWhiteMode {
  background: #C89C34;
}
.textGoldWhiteMode{
  color: #C89C34;
}
