.navIcon {
  width: 34px;
  height: 34px;
  border-radius: 15px;
}
.blur {
  filter: blur(2px);
}
.navNickname {
  font-weight: 700;
  line-height: normal;
  font-size: 14px;
  text-align: center;
  color: #fff;
  height: 15px;
}
.navNicknameWhite {
  color: black;
}
.navBar {
  background-color: black;
  height: 59.97px;
  font-size: 18.6px;
  line-height: 22px;
  color: #c0a76e;
  border-bottom: 2px solid #111111;
  position: relative;
  z-index: 1030;
  flex: 0 0 auto;
}
.navBar > :global(.am-navbar-title) {
  font-size: 18px;
  /*font-size: 18.6px;*/
}
.navBarWhite {
  border-bottom: 2px solid white;
  background-color: white;
  color: black;
}
.chatNavTitle {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 5px 0;
}
.textBlack {
  color: black;
}
@media screen and (device-aspect-ratio: 40/71) {
  .amNavbarTitle {
    font-size: 14px;
    line-height: 18px;
  }
}

/* iPhone X */
@media only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
  .navIcon {
    width: 34px;
    height: 34px;
    border-radius: 19px;
  }
  .navNickname {
    font-size: 14px;
    font-weight: 700;
  }
}

/* iPhone 8  */
@media only screen and (device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2) {
  .navIcon {
    width: 34px;
    height: 34px;
    border-radius: 19px;
  }
  .navNickname {
    font-size: 14px;
    font-weight: 700;
  }
}

/* iPhone 8 Plus */
@media only screen and (device-width: 414px) and (device-height: 736px) and (-webkit-device-pixel-ratio: 3) {
  .navIcon {
    width: 34px;
    height: 34px;
    border-radius: 19px;
  }
  .navNickname {
    font-size: 14px;
    font-weight: 700;
  }
}
