.container{
  display: flex;
  align-items: center;
}
.unread {
  line-height: normal;
  font-size: 10px;
  text-align: right;
  letter-spacing: 1px;
  white-space: nowrap;
  overflow: hidden;

  color: #ff0000;
  margin-right: -3px;
  text-decoration-line: underline;
}

.ellipse {
  width: 12px;
  height: 12px;
  border: 0.5px solid #ff0000;
  box-sizing: border-box;
  border-radius: 50%;
  position: relative;
  left: 5px;
  flex: 0 0 auto;
}

.questionMark {
  line-height: normal;
  font-size: 8px;
  text-align: right;
  color: #ff0000;
  position: relative;
  bottom: 0;
  right: 5px;
}

