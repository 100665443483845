.my-drawer {
  position: relative;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
.am-drawer-sidebar {
  background-color: red;
}
.my-drawer .am-drawer-sidebar {
  background-color: #fff;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
.my-drawer .am-drawer-sidebar .am-list {
  width: 300px;
  padding: 0;
  height: 100%;
}

.menuItem {
  display: flex;
  flex-direction: row;
  margin-left: 31px;
  margin-top: 27px;
}

.menuItemIcon {
  width: 24px;
  height: 24px;
  margin-right: 25px;
}

.menuItemText {
  font-weight: bold;
  color: white;
  margin-top: 2px;
}

.footer {
  height: 111px;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}

.line {
  margin-left: 31px;
  margin-right: 31px;
  height: 1px;
  background-color: #6e6e6e;
}

.menuItemFooter {
  width: 45px;
  height: 45px;
}

.leftFooter {
  display: flex;
  flex-direction: column;
  margin-left: 31px;
  height: 111px;
}

.rightFooter {
  display: flex;
  flex-direction: row;
  margin-right: 31px;
  flex: 1;
  align-items: center;
  justify-content: flex-end;
  height: 111px;
}

.contentFooter {
  display: flex;
  flex-direction: row;
}

.textFooter1 {
  color: #b7b7b7;
  font-weight: bold;
  margin-top: 23px;
}

.textFooter2 {
  color: #b7b7b7;
  font-size: 10px;
  margin-top: 19px;
}

.specificChar1 {
  font-size: inherit;
  letter-spacing: -9px; /* purpose: edit space for character */
}

.specificChar2 {
  font-size: inherit;
  letter-spacing: -4px; /* purpose: edit space for character */
}
.textDarkGray {
  color: #6e6e6e;
}
.textWhite {
  color: white;
}
.bgWhite {
  background-color: white;
}
