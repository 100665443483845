.messageContainer {
  margin-top: 10px;
}

.message {
  line-height: normal;
  font-size: 15px;
  text-align: left;
  padding-top: 20px;
  display: flex;
}

.messageLast {
  line-height: normal;
  font-size: 15px;
  text-align: left;
  padding-top: 20px;
  display: flex;
  color: #242728 !important;
}



/* iphone 5 */
@media screen and (device-aspect-ratio: 40/71) {
  .messageContainer {
    margin-top: 0px;
  }
  .message {
    font-size: 14px;
  }
  
  .messageLast {
    font-size: 14px;
    padding-top: 16px;
  }
}