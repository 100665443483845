.messageItem {
  margin-top: 27px;;
  margin-bottom: 20px;
  line-height: normal;
  font-size: 15px;
  text-align: left;
}

.messageListItem {
  margin-top: 4px;
  line-height: normal;
  font-size: 15px;
  text-align: left;
}
