.popup {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999999;
  outline: 0;
  overflow-x: hidden;
  overflow-y: auto;
}

.popup-open {
  overflow: hidden;
}

.popup-dialog {
  position: relative;
  width: auto;
  color: #000000;
  pointer-events: none;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
  max-width: 85%;
  margin: 25px auto;
}
.popup-centered {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  min-height: calc(100% - 50px);
}

.popup-content {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #e5e5e5;
  background-clip: padding-box;
  border: 1px solid #e5e5e5;
  border-radius: 8px;
  outline: 0;
  white-space: pre-line;
}

.popup-body {
  position: relative;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 42px 21px;
}

.popupPartnerDetail .popup-body {
  padding: 42px 16px;
}

.modal-backdrop {
  z-index: 999998;
}

.header {
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.content {
  height: auto;
  margin-bottom: 40px;
}
.content-has-bottom-link {
  margin-bottom: 60px;
}
.hyper-link {
  color: #c0a76e;
  text-decoration: underline;
  text-align: right;
}
.custom-hyper-link {
  margin-bottom: 20px;
  font-size: 15px;
}
.hyper-link-bold {
  font-weight: bold;
}
/*.hyper-link {*/
/*  line-height: normal;*/
/*  font-size: 13px;*/
/*  text-align: center;*/
/*  letter-spacing: 1px;*/
/*  color: hsl(42, 39%, 59%);*/
/*  !*box-shadow: -2px 2px 10px rgba(0, 0, 0, 0.25);*!*/
/*  border-radius: 25px;*/
/*  padding: 5px 0px 5px 0px;*/
/*  !*height: 30px;*!*/
/*  !*font-weight: 500;*!*/
/*}*/
.footer {
  height: auto;
}

.title {
  line-height: normal;
  font-size: 18px;
  color: #000000;
  text-align: center;
  font-weight: bold;
}
.horizontal-text {
  flex-direction: row;
  display: flex;
}
.underlined-text {
  text-decoration: underline;
  line-height: normal;
  font-size: 18px;
  color: #000000;
  text-align: center;
  font-weight: bold;
}
.message-container {
  margin-top: 25px;
}
.message {
  line-height: normal;
}

.message-bold {
  font-weight: bold;
}

.message-row-line {
  flex-direction: row;
  display: flex;
}

.message-row-line-margin {
  margin-left: 0.5px;
  margin-bottom: -15px;
}

.message-red {
  color: red;
}

.message-one-line {
  line-height: normal;
  text-align: center;
}

.message-line-break {
  margin-bottom: 15px;
}

.message-line-break {
  margin-bottom: 15px;
}

.message-multiple-lines {
  line-height: normal;
  text-align: left;
}

.button-container {
  display: flex;
  flex-direction: column;
}
.button {
  display: flex;
  height: 60px;
  text-align: center;
  align-items: center;
  justify-content: center;
  background-color: #c0a76e;
  color: #000;
  font-size: 16.6px;
  line-height: normal;
  letter-spacing: 1px;
  font-weight: 400;
}

.button-ok {
  color: #000;
  font-size: 16.6px;
  line-height: normal;
  text-align: center;
  letter-spacing: 1px;
  font-weight: 400;
}

.button-positive {
  margin-bottom: 16px;
}

.button-negative {
  background-color: #a0a0a0;
}

.button-disabled {
  opacity: 0.5;
}

/* Close button
*/
.close-button-container {
  position: absolute;
  right: -15px;
  top: -15px;
  width: 31px;
  height: 31px;
  opacity: 1;
  background-color: #e5e5e5;
  z-index: 1010;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.close-button {
  position: absolute;
  width: 27px;
  height: 27px;
  border-radius: 13.5px;
  opacity: 1;
  background-color: #c0a76e;
  z-index: 1010;
  display: flex;
  align-items: center;
  justify-content: center;
}
.close-button:hover {
  opacity: 1;
}
.close-button:before,
.close-button:after {
  position: absolute;
  content: ' ';
  height: 14px;
  width: 1px;
  background-color: white;
}
.close-button:before {
  transform: rotate(45deg);
}
.close-button:after {
  transform: rotate(-45deg);
}

.title--pre-line {
  white-space: pre-line;
}

.title--text-center {
  text-align: center;
}
.content-text-left {
  text-align: left;
}
.text-white{
  color: white;
}
.button-white-mode {
  background-color: #C89C34 !important;
}
/* iPhone X */
@media only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
  .title {
    font-size: 18px;
    font-weight: bold;
  }

  .message {
    font-size: 14px;
    font-weight: 300;
  }

  .button {
    font-size: 16.6px;
    font-weight: 400;
  }
}

/* iPhone 8  */
@media only screen and (device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2) {
  .title {
    font-size: 16px;
    font-weight: 600;
  }

  .message {
    font-size: 14px;
    font-weight: 300;
  }

  .button {
    font-size: 16.6px;
    font-weight: 400;
  }
}

/* iPhone 8 Plus */
@media only screen and (device-width: 414px) and (device-height: 736px) and (-webkit-device-pixel-ratio: 3) {
  .container {
    width: 80%;
  }

  .title {
    font-size: 18px;
    font-weight: bold;
  }

  .message {
    font-size: 15px;
    font-weight: 300;
  }

  .button {
    font-size: 16.6px;
    font-weight: 400;
  }
}

/* iphone 5 */
@media screen and (device-aspect-ratio: 40/71) {
  .title {
    font-size: 15px;
  }
  .message-container {
    margin-top: 21px;
  }
  .message {
    font-size: 13px;
  }
  .button {
    font-size: 15px;
    height: 54px;
  }
  .header {
    margin-top: 20px;
  }
  .footer {
    margin-top: 20px;
  }
  .button-container {
    margin-bottom: 33px;
  }
}
