.containerFull{
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.containerFullWhite {
  background-color: #F8F8F8;
}
.title {
  color: #c4c4c4;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 4px;
  text-align: left;
}
.requireItem {
  color: #9d150c;
  font-size: 10px;
  line-height: 14px;
}
.flexContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: auto;
}
.subTitle {
  color: #979797;
  font-weight: bold;
  font-size: 13px;
  text-align: left;
}

.titleWrapper {
  margin: 22px 22px 12px;
}

.ul {
  list-style: none;
}

.divider {
  background-color: #c0a76e;
  height: 1px;
  max-width: 369px;
  margin: 0 22px;
}

.pageDivider {
  background-color: #616161;
  height: 1px;
  max-width: 369px;
  margin: 0 22px;
}

.navIcon {
  width: 25px;
  height: 25px;
}

.container {
  margin: 22px 22px 36px;
}

.buttonGold {
  width: 289px;
  height: 60px;
  background: #c0a76e;
  color: #000000;
  font-size: 16.6px;
  line-height: normal;
  text-align: center;
  margin: auto auto 12px;
  padding: 16px 0;
}

.buttonGrey {
  width: 289px;
  height: 60px;
  background: #a0a0a0;
  color: #000000;
  font-size: 16.6px;
  line-height: normal;
  text-align: center;
  margin: auto auto 12px;
  padding: 16px 0;
}

.buttonGroup {
  display: flex;
  flex-flow: column nowrap;
}

.questionMark {
  position: relative;
  top: -4px;
  left: 3px;
  font-size: 12px;
  color: #c0a76e;
}

.ellipsis {
  position: absolute;
  width: 17px;
  height: 17px;
  border: 1px solid #c0a76e;
  border-radius: 8px;
  font-size: 11px;
}

.ellipsis::after {
  content: '？';
  position: relative;
  top: -2px;
  left: 2px;
  font-size: 12px;
  color: #c0a76e;
}

.iconQuestion {
  height: 20px;
  width: 20px;
  position: relative;
  top: 3px;
  color: #c0a76e;
  margin-left: 4px;
}

.textarea {
  padding: 4px;
  height: 140px;
  width: 100%;
  font-family: var(--NotoSans);
}

.textarea::placeholder {
  color: #6e6e6e;
  font-size: 15px;
  font-family: var(--NotoSans);
}

.popupTitleClassName {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: normal;
  text-align: center;
  color: #000000;
}

.popupMessageClassName {
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: normal;
  color: #000000;
  text-align: left;
  margin: 10px 0;
}

.input {
  border: 0;
  border-bottom: 2px solid #616161;
  color: #fff;
  background-color: #000;
  width: 100%;
  font-family: var(--NotoSans);
  max-width: 285px;
  display: flex;
  flex-flow: row nowrap;
  line-height: normal;
  margin: 0 auto;
}

.checkboxItem {
  font-size: 15px;
  line-height: normal;
  color: #ffffff;
  display: flex;
  flex-flow: row nowrap;
  margin-bottom: 17px;
  position: relative;
  align-items: center;
  text-align: left;
}

.checkboxUnchecked {
  height: 13px;
  width: 13px;
  border: 1px solid #c4c4c4;
  margin-right: 18px;
  border-radius: 1px;
}

.checkboxChecked {
  height: 13px;
  width: 13px;
  border: 1px solid #c4c4c4;
  background-color: #c4c4c4;
  margin-right: 18px;
  border-radius: 1px;
}

.checkboxCheckedInner {
  border: 2px solid #000;
  height: 11px;
  width: 11px;
}
.checkboxCheckedInnerWhiteMode {
  height: 11px;
  width: 11px;
  background-color: #C89C34;
  border: none;
  background-image: url("../../assets/newSystem/squareItem/svg/tick.svg");
}

.noPersonPreferReason {
  margin-left: 36px;
  margin-bottom: 17px;
}
.noPersonPreferReasonHidden {
  margin-left: 36px;
  margin-bottom: 17px;
  display: none;
}
.inputOtherNoPersonPreferReason {
  width: 70%;
}
.textWhite{
  color: white;
}
.textBlack{
  color: black !important;
}
.textGoldWhiteMode {
  color: #C89C34;
}
.buttonWhiteMode {
  background-color: #C89C34;
}
.textDarkGray {
  color: #6e6e6e;
}
.textareaWhiteMode{
  background-color: #E0E0E0;
  border: none;
}
.checkboxUncheckedWhiteMode {
  border: 1px solid black;
}
.inputWhiteMode{
  border-bottom: 2px solid #999999;
  background-color: #F8F8F8;
  color: black;
}
