.tabContainer {
  position: relative;
  width: 100%;
  z-index: 10000;
  flex: 0 0 auto;
  margin-top: auto;
}
.unselectParticipate {
  width: 22px;
  height: 22px;
  background: url(../../assets/participate_unselect.png) center center / 21px
    21px no-repeat;
}

.selectParticipate {
  width: 22px;
  height: 22px;
  background: url(../../assets/participate_select.png) center center / 21px 21px
    no-repeat;
}

.unselectDating {
  width: 22px;
  height: 22px;
  background: url(../../assets/dating_unselect.png) center center / 21px 21px
    no-repeat;
}

.selectDating {
  width: 22px;
  height: 22px;
  background: url(../../assets/dating_select.png) center center / 21px 21px
    no-repeat;
}

.unselectMyPage {
  width: 22px;
  height: 22px;
  background: url(../../assets/mypage_unselect.png) center center / 21px 21px
    no-repeat;
}

.selectMyPage {
  width: 22px;
  height: 22px;
  background: url(../../assets/mypage_select.png) center center / 21px 21px
    no-repeat;
}

.unselectMyDatingReport {
  width: 22px;
  height: 22px;
  background: url(../../assets/report_unselect.png) center center / 21px 21px
    no-repeat;
}

.selectMyDatingReport {
  width: 22px;
  height: 22px;
  background: url(../../assets/report_select.png) center center / 21px 21px
    no-repeat;
}

.unselectMyDatingReportWithBadge {
  width: 24px;
  height: 22px;
  background: url(../../assets/report_select_badge.png) center center / 24px
    21px no-repeat;
}
/* iPhone X, XS, 11, 11 Pro, 12, 13 */
@media only screen and (min-device-width: 375px) and (min-device-height: 812px) {
  .tabContainer.isIOS {
    padding-bottom: 14px;
    background-color: rgb(17, 17, 17);
  }
  .tabContainer.isIOSWhite{
    background-color: white;
  }
}

/* iPhone 12 Mini */
@media only screen and (device-width: 360px) and (device-height: 780px) {
  .tabContainer.isIOS {
    padding-bottom: 14px;
    background-color: rgb(17, 17, 17);
  }
  .tabContainer.isIOSWhite{
    background-color: white;
  }
}

