.wrapper {
  background-color: #cfb783;
  height: 5em;
  width: 5em;
  margin: auto;
  border-radius: 5px;
  z-index: 1999999;
  position: absolute;
  display: inline;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1999999;
  background-color: rgba(0, 0, 0, 0.5);
  touch-action: none;
}
.overlayWhite {
  background-color: #F8F8F8;
}
.wrapperWhiteMode {
  background-color: #C89C34;
}
.overlayWhiteBlur {
  opacity: 0.8;
}
