.read {
  line-height: normal;
  font-size: 10px;
  text-align: right;
  letter-spacing: 1px;
  white-space: nowrap;
  overflow: hidden;

  color: #000000;
}

