.chatRoom {
  overflow: scroll;
  padding: 18px 21px 0;
  flex: 1;
}

.scrollTo {
  display: flex;
  flex-flow: row nowrap;
  margin-bottom: 15px;
}

.loadingMessageContainer {
  display: flex;
  flex: 1;
  justify-content: flex-end;
}