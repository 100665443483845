.partnerTextMessage {
  display: flex;
  flex-flow: row nowrap;
  margin-bottom: 12px;
  align-items: flex-end;
  position: relative;
  white-space: pre-wrap;
}

.chatUserIcon {
  flex: 0 0 auto;
  width: 37.75px;
  height: 38px;
  left: 21.5px;
  background-color: black;
  border-radius: 50%;
  margin-right: 20px;
}

.partnerTextShowDate {
  padding-top: 35px;
}

.partnerTextShowTime {
  padding-bottom: 10px;
}

.chatPartnerTextBubble {
  height: min-content;
  max-width: 293px;
  background: #ffffff;
  border-radius: 26px;
  padding: 11px 16px;
  word-wrap: break-word;
  line-height: normal;
  font-size: 13px;
  letter-spacing: 1px;
  overflow-x: hidden;
}

.chatPartnerCreateTime {
  line-height: normal;
  font-size: 10px;
  text-align: right;
  letter-spacing: 1px;
  color: #a0a0a0;
  position: absolute;
  left: 73.75px;
  bottom: -7px;
}

.chatPartnerCreateDate {
  line-height: normal;
  position: absolute;
  font-size: 12px;
  color: #a0a0a0;
  left: 0;
  right: 0;
  margin: auto;
  width: 100%;
  text-align: center;
  top: 10px;
}

.blur {
  filter: blur(3px);
}
.textBlack{
  color: black;
}
