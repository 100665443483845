.chatTextInput {
  display: flex;
  flex-flow: row nowrap;
  position: relative;
  margin: auto 23px 0;
  flex: 0 0 auto;
  border: 1px solid #dddddd;
  box-sizing: border-box;
  border-radius: 149px;
  padding: 16px 60px 16px 26px;
  background-color: #f1f1f1;
  align-items: center;
}

.textInputBox {
  background-color: #f1f1f1;
  resize: none;
  overflow: hidden;
  border: 0;
  padding: 0;
  width: 100%;
  flex-grow: 1;
}

.textInputBox::placeholder {
  width: 135px;
  height: 21.5px;
  line-height: normal;
  font-size: 14px;
  letter-spacing: 1px;
  color: #a0a0a0;
  font-weight: 500;
}

.sendButtonText {
  line-height: normal;
  font-size: 14px;
  text-align: right;
  letter-spacing: 1px;
  color: #a0a0a0;
  position: absolute;
  right: 24px;
  flex-grow: 0;
  display: flex;
  align-items: center;
}

.sendButtonImg {
  width: 38px;
  height: 32px;
}

.loadingChatContainer {
  width: 50px;
  position: absolute;
  top: 0;
  right: 15px;
}

.textInputBoxWhiteMode {
  background-color: #F8F8F8;
}
.textInputBoxWhiteMode::placeholder {
  color: #999999;
}
.chatTextInputWhiteMode {
  border: 1px solid #C4C4C4;
  background-color: #F8F8F8;
}
